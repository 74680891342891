<template>
  <div class="pb-32">
    <h2 class="text-xl font-bold mb-4">Incoming Wema Transactions</h2>

    <template>
      <div>
        <button
          @click="downloadCSV"
          class="btn-sm btn-blue rounded-sm px-6 mb-2"
        >
          Download CSV
        </button>
      </div>
      <div>
        <div class="border border-solid border-blue-200 pt-4">
          <datatable
            :ajax="true"
            :ajax-pagination="true"
            :ajax-headers="wemaTableHeaders"
            @ajaxDataFetched="(data) => {
              data.data.page_count = data.data.totalPages;
              data.datatable = data.data;
            }"
            url="https://virtual.credpal.com/api/virtual-account/statement"
            :rangeable="wemaTransactions.dateRange"
            :columns="wemaTransactions.columns"
            :limit="25"
            :data="wemaTransactions.data"
            ref="wemaTransactionsTable"
          />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  data() {
    return {
      wemaTransactions: this.$options.resource([], {
        data: [],


        columns: [
          {
            name: 'sessionid',
            th: 'Session ID',
          },
          {
            name: 'paymentreference',
            th: 'Payment Reference',
          },
          {
            name: 'originatoraccountname',
            th: 'Originator Account Name',
          },
          {
            name: 'originatoraccountnumber',
            th: 'Originator Account Number',
          },
          {
            name: 'bankname',
            th: 'Bank Name',
          },
          {
            name: 'amount',
            th: 'Amount',
            render: (transaction) =>
              this.$options.filters.currency(transaction.amount),
          },
          {
            name: 'narration',
            th: 'Narration',
          },
          {
            name: 'extResponse',
            th: 'Ext Response',
          },
          {
            name: 'craccount',
            th: 'CR Account',
          },
          {
            name: 'beneficiaryaccountname',
            th: 'Beneficiary Account Name',
          },
          {
            name: 'sourcebank',
            th: 'Source Bank',
          },
          {
            name: 'nibsresstatus',
            th: 'NIBSRESS Status',
          },
          {
            name: 'datePosted',
            th: 'Date Posted',
            render: (_, datePosted) => this.$options.filters.dateFormat(datePosted)
          },
        ],

        loading: false,
        dateRange: {
          value: {
            startDate: '2023-11-01',
            endDate: '2023-11-30',
          },
          dateParamKeys: ['start_date', 'end_date'],
          format: 'yyyy-mm-dd',
        },
      })
    };
  },
  computed: {
    wemaTableHeaders () {
      return {Authorization: process.env.VUE_APP_WEMA_TOKEN}
    },
  },

  methods: {
    downloadCSV() {
      this.parseObjectToCsvFile(this.$refs.wemaTransactionsTable.ajaxItems);
    },
  },
};
</script>
